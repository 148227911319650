.heroContainer {
  height: 72vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  -webkit-clip-path: circle(0 at 50% 50%);
  clip-path: circle(0 at 50% 50%);
  /* background: linear-gradient(
    180deg,
    #fff 0%,
    #fff 50%,
    #efefef 50%,
    #efefef 100%
  ); */
  background: linear-gradient(
    166deg,
    #fff 0%,
    #fff 50%,
    #ffbebe 50%,
    #ffbebe 100%
  );
}

.heroAfterAnime {
  -webkit-clip-path: circle(100% at 50% 50%);
  clip-path: circle(100% at 50% 50%);
}

.heroAnime {
  -webkit-animation: show_image cubic-bezier(0.06, 0.25, 0.38, -0.46) 2s 3.3s
    forwards;
  animation: show_image cubic-bezier(0.06, 0.25, 0.38, -0.46) 2s 3.3s forwards;
}

@-webkit-keyframes show_image {
  0% {
    -webkit-clip-path: circle(0 at 50% 50%);
    clip-path: circle(0 at 50% 50%);
  }
  100% {
    -webkit-clip-path: circle(100% at 50% 50%);
    clip-path: circle(100% at 50% 50%);
  }
}

@keyframes show_image {
  0% {
    -webkit-clip-path: circle(0 at 50% 50%);
    clip-path: circle(0 at 50% 50%);
  }
  100% {
    -webkit-clip-path: circle(100% at 50% 50%);
    clip-path: circle(100% at 50% 50%);
  }
}

.heroImage {
  width: 50%;
  max-width: 40rem;
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 1rem;
}

.heroMessage {
  width: 40%;
  max-width: 35rem;
  font-size: 1rem;
  line-height: 1.9rem;
  overflow-wrap: break-word;
}

.heroGuide {
  position: absolute;
  width: 1.7rem;
  height: 5rem;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1rem;
}

.heroGuide::before {
  width: 100%;
  height: 100%;
  margin: auto;
}

@media (max-width: 800px) {
  .heroContainer {
    flex-direction: column;
    justify-content: center;
  }

  .heroImage {
    margin-top: 0.2rem;
    margin-bottom: 0;
    width: 80%;
    max-width: 30rem;
  }

  .heroMessage {
    width: auto;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .heroContainer {
    height: 67vh;
    flex-direction: column;
    justify-content: center;
  }

  .heroMessage h2 {
    font-size: 1rem;
    margin: 0;
  }

  .heroMessage p {
    font-size: 0.8rem;
    margin: 0;
  }
}