.headerContainer {
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-weight: 600;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  z-index: 1;
}

.headerAnime {
  -webkit-animation: header_move 1s 3s both;
  animation: header_move 1s 3s both;
}

@-webkit-keyframes header_move {
  0% {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes header_move {
  0% {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.headerLeftBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-items: center;
}

.headerName,
.headerSkills,
.headerProjects,
.headerResume {
  text-decoration: none;
  color: #000;
}

.headerName::after,
.headerSkills::after,
.headerProjects::after,
.headerResume::after {
  position: absolute;
  bottom: 0.9rem;
  content: "";
  height: 1px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.3s;
}

.headerName::after {
  left: 0.8rem;
  width: 5.7rem;
}

.headerSkills::after {
  left: 8.1rem;
  width: 3.7rem;
}

.headerProjects::after {
  left: 13.4rem;
  width: 5.7rem;
}

.headerResume::after {
  right: 0.8rem;
  width: 4.5rem;
}

.headerName:hover::after,
.headerSkills:hover::after,
.headerProjects:hover::after,
.headerResume:hover::after {
  transform: scale(1, 1);
}

@media (max-width: 500px) {
  .headerContainer {
    font-size: 0.8rem;
  }

  .headerLeftBox {
    grid-gap: 0;
  }
}
