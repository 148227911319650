.footerContainer {
  position: relative;
  box-sizing: border-box;
}

footer {
  width: 100%;
  color: #000;
  text-align: center;
  font-size: 0.7rem;
  padding: 0.5rem 0;
}
