.projectContainer {
  padding: 2rem 1.4rem 1rem;
  font-weight: 600;
  background-color: #fbfbfb;
}

.projectTitle {
  margin: 0.8rem 0;
  font-size: 1.2rem;
}

.projectBox {
  width: 95%;
  height: 40rem;
  margin: 1rem auto;
  border: 0.5px solid #ccc;
  overflow-y: scroll;
}

@media (max-width: 500px) {
  .projectTitle {
    font-size: 1rem;
  }
}
