.projectLink {
  text-decoration: none;
  color: #000;
}

.projectLink:hover img {
  transform: scale(1.2, 1.2);
}

.projectCard {
  margin: 2rem;
  overflow: hidden;
}

.projectTitle {
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
}

.projectSkillBox {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.projectSkill {
  margin: 0 0.3rem 0.8rem 0.3rem;
  padding: 0.1rem 0.5rem;
  font-weight: 200;
  font-size: 0.9rem;
  background-color: #e6e6e6;
  border-radius: 0.2rem;
}

.projectBox {
  display: flex;
  margin-top: 0.5rem;
}

.projectImageBox {
  display: flex;
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
}

.projectImage {
  -o-object-fit: cover;
  object-fit: cover;
  transition-duration: 0.3s;
  -o-object-position: center 30%;
  object-position: center 30%;
  width: 100%;
}

.projectExplanation {
  width: 100%;
}

.projectExplanation p {
  font-weight: 200;
  margin: 1rem;
  line-height: 1.8rem;
}

.hrDesign {
  width: 95%;
  margin-top: 2rem;
  border-top: 1px dashed #ccc;
}

@media (max-width: 800px) {
  .projectCard {
    margin: 0.8rem;
  }

  .projectBox {
    flex-direction: column;
  }

  .projectImageBox {
    border-bottom: 1px solid #ccc;
    /* width: 80%; */
  }

  .projectExplanation p {
    margin: 1rem 0.5rem 0;
  }
}

@media (max-width: 500px) {
  .projectTitle {
    font-size: 1.2rem;
  }

  .projectSkill {
    font-size: 0.8rem;
  }

  .projectExplanation p {
    font-size: 0.9rem;
  }
}
