.skillContainer {
  padding: 2rem 1.4rem 1rem;
  font-weight: 600;
}

.skillBox {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  justify-items: center;
}

.skillItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillItem svg {
  width: 1.5rem;
  height: 1.5rem;
}

.skillItem p {
  font-size: 0.7rem;
  margin: 0.3rem 0;
  font-weight: 400;
  text-align: center;
}

.skillTitle {
  font-size: 1.2rem;
}

.skillSubTitle {
  margin: 1rem;
}

.titleIcon {
  margin: 0 0.4rem;
}

@media (max-width: 500px) {
  .skillTitle {
    font-size: 1rem;
  }

  .skillSubTitle {
    font-size: 0.8rem;
    margin: 1rem 0.2rem;
  }
}