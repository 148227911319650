@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@200;400;600&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Red Hat Display", sans-serif;
  margin: 0;
}

.nameAnimationHide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  -webkit-animation: name_hide 1.5s 3s forwards;
  animation: name_hide 1.5s 3s forwards;
  z-index: 10;
}

@-webkit-keyframes name_hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}

@keyframes name_hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}

.nameAnimationContainer {
  position: absolute;
  width: 100%;
  height: 38px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nameAnimationCover {
  width: 326px;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.nameAnimationContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-animation: name 2s steps(25) both;
  animation: name 2s steps(25) both;
}

@-webkit-keyframes name {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4205%);
  }
}

@keyframes name {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4205%);
  }
}

.nameAnimationContent::before {
  content: url(../../assets/images/name.svg);
  width: 100%;
  height: 100%;
}

#PathSVG {
  fill: black;
}
