.projectTitle {
  margin-bottom: 0.8rem;
  margin-left: 2rem;
  font-size: 1.8rem;
  -webkit-animation: title 2s forwards;
  animation: title 2s forwards;
}

@-webkit-keyframes title {
  0% {
    opacity: 0;
    transform: translateY(5%);
    -webkit-transform: translateY(5%);
    -moz-transform: translateY(5%);
    -ms-transform: translateY(5%);
    -o-transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes title {
  0% {
    opacity: 0;
    transform: translateY(5%);
    -webkit-transform: translateY(5%);
    -moz-transform: translateY(5%);
    -ms-transform: translateY(5%);
    -o-transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.projectContainer {
  padding: 1rem 2rem;
  -webkit-animation: title 2s forwards;
  animation: title 2s forwards;
}

.projectImageBox {
  display: flex;
  width: 90%;
  border: 1px solid #ccc;
  margin: auto;
}

.projectImage {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center 30%;
  object-position: center 30%;
  width: 100%;
}

.projectURL {
  font-weight: 600;
  margin-top: 1.8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.projectURL a {
  font-size: 1rem;
  text-decoration: none;
  color: #000;
  background-color: #e6e6e6;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
}

.projectURL a:hover {
  background-color: #ccc;
}

.projectURL a svg {
  margin-left: 0.3rem;
}

.why,
.who,
.where,
.what {
  margin: 2rem;
}

.whyTitle,
.whoTitle,
.whereTitle,
.whatTitle {
  font-weight: 600;
  font-size: 1.5rem;
}

.whyDescription,
.whoDescription,
.whereDescription,
.whatDescription {
  margin: 1rem auto;
  width: 90%;
  font-size: 1.1rem;
}

@media (max-width: 800px) {
  .projectContainer {
    padding: 1rem 0.8rem;
  }

  .projectTitle {
    margin-bottom: 0;
  }

  .projectImageBox {
    width: 80%;
  }

  .why,
  .who,
  .where,
  .what {
    margin: 0.8rem;
  }

  .whyTitle,
  .whoTitle,
  .whereTitle,
  .whatTitle {
    font-weight: 600;
    font-size: 1rem;
  }

  .whyDescription,
  .whoDescription,
  .whereDescription,
  .whatDescription {
    margin: 1rem auto;
    width: 90%;
    font-size: 0.8rem;
  }
}
